<template>
  <div class="site-cont">
    <div class="search-store-title">400电话查询</div>
    <div class="search-wrap">
      <div class="flex-cont">
        <div class="icon">
          <find theme="outline" fill="#333" :strokeWidth="2"/>
        </div>
        <div class="flex-input">
          <cube-input class="select" v-model="form.brand" placeholder="请输入品牌" :clearable="true"></cube-input>
        </div>
        <div>
          <cube-button class="btn" :light="true" @click="getAll">查询</cube-button>
        </div>
      </div>
    </div>
    <div class="map-wrap">
      <div class="map-list-one">
        <div class="map-list-one-cnt">
          <!-- <div class="map-list-one-title">{{city.name}}</div> -->
          <p class="number-stores">{{list.length}} 个结果</p>
        </div>
      </div>
      <ul class="map-list" >
        <li v-for="(item, index) in list" :key="index">
          <div class="map-list-cnt">
            <div class="map-list-title">
              <div class="font-title">{{item.brand}}</div>
            </div>
            <div class="map-list-address icon-flex" v-show="item.officialWebsite">
              <browser-chrome theme="outline" fill="#333" :strokeWidth="2"/>
              <div class="text-cont">
                <div>
                  {{item.officialWebsite}}
                </div>
              </div>
            </div>
            <div class="map-list-phone icon-flex">
              <phone-telephone class="icon2" theme="outline" fill="#333" :strokeWidth="2"/>
              <div class="text-cont">
                <div>
                {{item.telephone}}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- <div class="list-pager-wrap">
        <div class="list-pager-title">已展示10条记录 / 共100条</div>
        <div class="list-pager-bnt">加载更多</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {Find, BrowserChrome, PhoneTelephone} from '@icon-park/vue'

export default {
  components: {
    Find,
    BrowserChrome,
    PhoneTelephone
  },
  data () {
    return {
      brands: [],
      form: {
        "brand":"",
      },
      list: [], 
      city: {
        name: '北京市',
        list: []
      }
    }
  },
  mounted () {
    this.getAll()
  },
  methods: {
    /**
     * 400列表
     */
    getAll () {
      const vm = this;
      let postData = {...this.form}
      vm.$store.commit('updateLoader', true);
      this.$axios({
        method: 'post',
        baseURL: this.$init.apis.default,
        url: "/dic/officialTelephone/queryOfficialTelephone",
        data: postData,
        timeout: 600000,
      }).then(function(response) {
          vm.$store.commit('updateLoader', false);
          vm.list = response.data.body.officialTelephoneList ? response.data.body.officialTelephoneList : []
        })
        .catch(function(err) {
          // vm.$store.commit('updateLoader', false);
        });
    },
    
  },
}
</script>

<style lang="scss" scoped>
.btn{
  height: 70px;
  padding: 0 30px;
  font-size: 28px;
  color: #000;
}
.icon{
  font-size: 48px;
}
.flex-cont{
  display: flex;
  align-items: center;
  .flex-input{
    flex: 10;
    margin-right: 20px;
  }
}
.site-cont{
  min-height: 100.1vh;
  box-sizing: border-box;
  padding: 80px 0;
}
.search-store-title{
  text-align: center;
  font-size: 52px;
  line-height: 64px;
  color: #000;
}
.site-cont .select{
  border: none;
  &::after{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
.search-wrap{
  padding: 40px 32px 0;
}

.bnt-list{
  padding-top: 48px;
  line-height: 60px;
  font-size: 24px;
  display: flex;
  .app-select-city{
    flex: 10;
    color: #000;
  }
  .near-me-wrap{
    margin-left: 20px;
    background: #f5f5f5;
    border-radius: 60px;
    padding: 0 40px;
    text-align: center;
    color: rgba(0,0,0,0.52);
  }
}
.map-wrap{
  margin-top: 40px;
}

.map-list-title{
  font-size: 32px;
  line-height: 48px;
  color: #000;
  word-break:break-all; 
}
.map-list-address{
  padding-top: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
  word-break:break-all; 
}
.map-list-phone{
  padding-top: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
}
.map-list-one{
  padding: 32px;
  font-size: 28px;
  line-height: 48px;
  color: rgba(0,0,0,0.5);
  border-bottom: 1px solid #eaeaea;
}
.map-list{
  li{
    padding: 48px 32px;
    border-bottom: 1px solid #eaeaea;
  }
}
.list-pager-wrap{
  padding-top: 80px;
  text-align: center;
  .list-pager-title{
    font-size: 32px;
    margin-bottom: 60px;
    color: rgba(0,0,0,0.5);
  }
  .list-pager-bnt{
    margin: 0 auto;
    border: 4px solid rgba(0,0,0,0.30);
    border-radius: 48px;
    display: inline-block;
    font-size: 26px;
    color: #000;
    letter-spacing: 0;
    line-height: 32px;
    padding: 24px 48px;
    font-weight: bold;
  }
}
</style>
